import { AppProps } from 'next/app';
import { NextPage } from 'next';
import App from 'next/app';
import 'index.css';
import React from 'react';
import i18nLib from '../i18n';

//@ts-ignore
const MyApp: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {
    return <Component {...pageProps} />;
};

//@ts-ignore
MyApp.getInitialProps = async appContext => {
    //@ts-ignore
    const appProps = await App.getInitialProps(appContext);

    const { defaultProps } = (appContext as any).Component;

    if (defaultProps?.i18nNamespaces) {
        return { ...appProps, pageProps: { namespacesRequired: defaultProps?.i18nNamespaces } };
    }

    return { ...appProps };
};

export default i18nLib.appWithTranslation(MyApp);
